import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { LoginProvider } from 'components/authentication/card/LoginProvider';
import './main.css';
const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
    <LoginProvider>
      <Main>
        <App />
      </Main>
    </LoginProvider>
);