import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import OTPInput from "otp-input-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const handleFieldChange = e => {
    setEmail(e.target.value);
  };

  const [otp, setOtp] = useState();
  const [otpSent, setOtpSent] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleVerifyOTP = async () => {

    try {
      const response = await axios.post(`${apiUrl}/user/verifyotp`, { otp, email});
      // Handle successful login
      console.log('Login successful', response.data);
      if(response.data.success)
      {
        console.log('OTP verified successfully!');
        toast.success(`${response.data.message}`, {
                    theme: 'colored'
                  });
                    
          setOtpSent(true);
          navigate('/reset-password');
      }
      else
      {
        console.log("res>>", response.data);
        toast.error(`${response.data.message}`);
      } 
     
    } catch (error) {
      // Handle login error
      toast.error(`${error}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/user/forgotpassword`, {email});
      // Handle successful login
      console.log('Login successful', response.data);
      if(response.data.success)
      {
          console.error('resp>>>', response.data);
          toast.success(`An email is sent to ${email} with password reset link`, {
            theme: 'colored'
          });
          setOtpSent(true);
      }
      else
      {
        console.log("res>>", response.data);
        toast.error(`${response.data.message}`);
      } 

    } catch (error) {
      // Handle login error
      toast.error(`${error}`);
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      {otpSent ? (
        <Form.Group className="mb-3">
          <OTPInput 
            value={otp} 
            //name="otp" 
            onChange={setOtp} autoFocus 
            OTPLength={4} 
            otpType="number" 
            disabled={false} secure 
          />
        </Form.Group>
      ) : null}
       <Form.Group className="mb-3">
        {otpSent ? (
          <Button className="mt-3" onClick={handleVerifyOTP} disabled={!otp}>
            Verify OTP
          </Button>
          
        ) : (
          <Button type="submit" disabled={!email}>Send OTP</Button>
        )}
        {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
      </Form.Group>

    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'card' };

export default ForgetPasswordForm;
