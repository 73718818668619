import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: ''
  });

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();
  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(`${apiUrl}/user/setpassword`, formData, { withCredentials: 'true'});
      if(response.data.success)
      {
        toast.success(`${response.data.message}`, {
                    theme: 'colored'
                  });

        navigate('/');

      }
      else
      {
        console.log("res>>", response.data);
        toast.error(`${response.data.message}`);
      } 
    } catch (error) {
      // Handle login error
      toast.error(`Error: ${error}`);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
