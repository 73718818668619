import React, { useContext, useState } from 'react';
import { LoggedInUserContext } from 'context/Context';
//const MyContext = createContext();

export const useLoggedInUserContext = () => {
  return useContext(LoggedInUserContext);
};

export const LoginProvider = ({ children }) => {
  const [responseData, setResponseData] = useState(null);

  const updateResponseData = (data) => {
    setResponseData(data);
  };

  return (
    <LoggedInUserContext.Provider value={{ responseData, updateResponseData }}>
      {children}
    </LoggedInUserContext.Provider>
  );
};