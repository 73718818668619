import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';
import FalconCardHeader from 'components/common/FalconCardHeader';

const AddCompany = () => {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [status, setStatus] = useState([]);

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'In-active' }
  ];

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchCountries = async () => {
    try {
      console.log('apiUrl', apiUrl);

      axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded';

      const response = await axios.get(`${apiUrl}/country`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });

      // Handle successful login
      if (response.data.success) {
        console.log('countries list', response.data);
        setCountries(response.data.data);
      } else {
        console.log('res>>', response.data);
        toast.error(`${response.data.message}`);
      }
      // Perform redirection or other actions here
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  // const fetchCities = async () => {

  //     try {
  //         const response = await axios.get(`${apiUrl}/city`,{ withCredentials: 'true'});
  //         // Handle successful login
  //         if(response.data.success)
  //         {
  //           console.log('cities list', response.data);
  //           setCountries(response.data.data);
  //         }
  //         else
  //         {
  //           console.log("cities>>", response.data);
  //           toast.error(`${response.data.message}`);
  //         }
  //         // Perform redirection or other actions here
  //       } catch (error) {
  //         toast.error(`${error}`);
  //       }
  // };

  useEffect(() => {
    fetchCountries();
    //fetchCities();

    // fetchCountries();
    // fetchCities();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="ADD COMPANY" titleTag="h5" light />
      <Card.Body className="p-3">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="comp_name"
                type="text"
                placeholder="Enter Name"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                name="comp_phone"
                type="number"
                placeholder=" Enter Phone no."
              />
            </Form.Group>
          </Row>

          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>City</Form.Label>
              <Select
                closeMenuOnSelect={false}
                options={cities}
                placeholder="Select..."
                isMulti
                classNamePrefix="react-select"
                value={cities}
                onChange={cities => setCities(cities)}
              ></Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>Country</Form.Label>
              <Select
                closeMenuOnSelect={false}
                options={countries}
                placeholder="Select..."
                isMulti
                classNamePrefix="react-select"
                value={countries}
                onChange={countries => setCountries(countries)}
              ></Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridStatus">
              <Form.Label>Status</Form.Label>
              <Select
                closeMenuOnSelect={false}
                options={statusOptions}
                placeholder="Select..."
                classNamePrefix="react-select"
                value={status}
                onChange={status => setStatus(status)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCompCategory">
              <Form.Label>Company Category</Form.Label>
              <Form.Select defaultValue="Choose...">
                <option>Choose...</option>
                <option>...</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formGridAddress">
            <Form.Label>Address </Form.Label>
            <Form.Control placeholder="Apartment, studio, or floor" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formGridDesc">
            <Form.Label>Description</Form.Label>
            <Form.Control placeholder="" />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddCompany;
