import React from 'react';
// import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      // leftSideContent={
      //   <p className="">
      //     Don't have an account?
      //     <br />
      //     <Link
      //       className="text-decoration-underline"
      //       to="/authentication/card/register"
      //     >
      //       Get started!
      //     </Link>
      //   </p>
      // }
    >
      <h3 className='text-white'>Account Login</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
