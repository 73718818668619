// import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import { useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import SocialAuthButtons from './SocialAuthButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLoggedInUserContext } from './card/LoginProvider';

const LoginForm = ({ hasLabel }) => {
  // State
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const { updateResponseData } = useLoggedInUserContext();
  const { responseData } = useLoggedInUserContext();

  const navigate = useNavigate();
  // Handler

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/user/signin`, formData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });

      // Handle successful login
      if (response.data.success) {
        console.log('Login successful', response.data);
        updateResponseData(response.data);

        toast.success(`Logged in as ${formData.email}`, {
          theme: 'colored'
        });
        console.log('LoggedInUserData>>>', responseData);
        navigate('/userdashboard');
      } else {
        console.log('res>>', response.data);
        toast.error(`${response.data.message}`);
      }
      // Perform redirection or other actions here
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type={passwordVisible ? 'text' : 'password'}
        />
        <FontAwesomeIcon
          onClick={togglePasswordVisibility}
          className="toggle-password-button"
          icon={passwordVisible ? faEye : faEyeSlash}
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0 link-light" to={`/forgot-password`}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="light"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'card',
  hasLabel: false
};

export default LoginForm;
